body {
  background-color: #3E3637;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.pageHeaderContainer {
  width:  100%;
  display: block;
  overflow: hidden;
  margin: 0 auto;
}
.pageHeader {
  text-align: center;
  top: 0px;
  left: 1px;
  height: 150px;
  background: transparent linear-gradient(101deg, #ffffff 0%, #f5f2f2 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.logoContainer {
  float: left;
  width: 250px;
  height: 140px;
  margin-top: 20px;
}
.infoContainer {
  margin: 25px;
  font-size: 36pt;
  line-height: 65px;
  color: white;
  font-weight: bold;
}

.dateContainer {
  height: 100px;
  width: 600px;
  background: #FFCC00  0% 0% no-repeat padding-box;
  margin-right: 2%;
  padding: 10px;
  padding-bottom: 25px;
  font-size: 50pt !important;
  border-radius: 8px;
  opacity: 1;
  float: right;
  color: #3E3637;
}

.smallInfo {
  height: 100px;
  width: 220px;
  background: #3E3637  0% 0% no-repeat padding-box;
  margin-right: 2%;
  padding: 10px;
  padding-bottom: 25px;
  font-size: 50pt !important;
  border-radius: 8px;
  opacity: 1;
  float: right;
}
.parent {
  width: 40%;
  height: 900px;
  border: 1px solid #AAA;
  overflow: hidden;
  padding-left: 1px;
  float: left;
}

.child {
  height: 100%;
  margin-right: -50px; /* Maximum width of scrollbar */
  padding-right: 50px; /* Maximum width of scrollbar */
  overflow-y: scroll;
  overflow-x: hidden;
}

.tableContainer{
  overflow:scroll;
  overflow-x: hidden;
  overflow-y: initial;
}
.journeyTable {
  width: 100%;
  text-align: center;
  line-height: 80px;
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 30px;
  float: left;
  margin-left: 7px;
}

.journeyTable thead tr th {
  background-color: #FFCC00;
  color: #3E3637;
  font-weight: bold;
  border-bottom: 3px solid #000;
  padding: 5px 8px;
}

.journeyTable tbody tr td {
  background-color: lightgray;
  font-weight: bold;
  border-bottom: 3px solid #000;
   border-radius: 4px;
  color: black;
  padding: 5px 8px;
}
.journeyTable .kalkmis {
  background-color: #FDFFB7;
}
.left{
  text-align: left;
}
.floatLeft{
  float: left;
  padding-top: 150px;
}
.busTypeColumn{
  width: 12px;
}
.nextJourney{
  animation: journey 1s ease-in-out infinite;
}

@keyframes journey {
  0% {
    background-color: lightgray;
  }
  50% {
    background-color: orange;
  }
  100% {
    background-color: orangered;
  }
}
